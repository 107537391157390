<template>
  <div class="flex-row">
    <div class="company-wrapper company-wrapper-skeleton" v-if="isLoading">
      <mu-circular-progress class="demo-circular-progress" :size="26"></mu-circular-progress>
    </div>
    <div class="company-wrapper" v-if="promoPage.id && !isLoading">
      <company-head-nav
          :leftNav="true"
          @closeItem="close()"
          @promoFavoriteCompanyEvent="toggleFavFromCompanyHeadNav"
          @promoGoToOwnerMessages="goToOwnerMessages"
          :isFavoriteCompany="isFavoriteCompany"
          :video_stream_url="promoPage.video_stream_url"
          :external_id="external_id"
          @enable-fullscreen-mode="enableFullscreenMode"
      ></company-head-nav>

      <div :class="{'overlay overlay-visible': fullscreenMode}">
        <div class="close-icon" @click="enableFullscreenMode(false)">
          <iconZoomOut :color="'#fff'"></iconZoomOut>
        </div>
        <company-media-block
          :external-id="promoPage && promoPage.external_id"
        ></company-media-block>
      </div>

      <div class="company">
        <div class="relative head-block">
          <div class="avatar">
            <img :src="setCompanyAvatar(promoPage.logo_url)" alt="image"/>
          </div>
          <div class="title relative">
            <div class="promopage-viewers-wrap" v-if="isAllowedToViewPromopageViewers">
              <button class="cur-p promopage-viewers" @click="proceedToPromopageViewerContacts()">
                {{ $t('promopageViewerContacts') }}
              </button>
            </div>
            <span class="inline-block max-width">{{truncatedText(promoPage.title, 75)}}</span>
            <div class="location" v-if="promoPage.country && promoPage.city">
              {{ promoPage.city.name }}, {{ promoPage.country.name }}
            </div>
            <div class="website" v-if="promoPage.website"><a :href="promoPage.website" target="_blank">{{promoPage.website}}</a></div>
          </div>
        </div>
        <div class="nav">
          <span class="tab cur-p" :class="{'highlight': navSelected === 'info'}"
                @click="navSelect('info')">{{$t('title.info')}}</span>
          <span class="tab cur-p" :class="{'highlight': navSelected === 'products'}"
                @click="navSelect('products')">{{$t('title.products')}}</span>
          <span class="tab cur-p" :class="{'highlight': navSelected === 'team'}"
                @click="navSelect('team')">{{$t('title.team')}}</span>
          <span class="tab cur-p" :class="{'highlight': navSelected === 'documents'}"
                @click="navSelect('documents')">{{$t('title.documents')}}</span>
          <span class="tab cur-p" :class="{'highlight': navSelected === 'notes'}"
                @click="navSelect('notes')">{{$t('title.notes')}}</span>
        </div>
      </div>
      <div class="field-block" v-if="navSelected === 'info'">
        <div class="field-header">
          <div class="title">{{$t('title.info')}}</div>
        </div>
        <div class="field-content">
          <div class="content">
            <div class="description ws-pre-wrap">
              <truncate action-class="read-more" v-if="promoPage.description"
                        :length="180"
                        :less="$t('button.less')"
                        :clamp="$t('button.more')"
                        type="html"
                        :text="generateLinks(promoPage.description)"></truncate>
            </div>
          </div>
        </div>
      </div>

      <!-- Product list -->
      <div class="field-block field-block-products"
           v-if="navSelected === 'info' || navSelected === 'products'"
           :class="{
              'field-block-products-short' : navSelected !== 'products',
              'field-block-products-full' : navSelected === 'products'
           }"
      >
        <div class="field-header">
          <div class="title">{{$t('title.products')}}</div>
          <div class="action" v-if="navSelected !== 'products'">
            <a class="btn cur-p" @click="showAllProducts()">{{
              $t('button.seeAll')}}</a>
          </div>
        </div>
        <div class="field-content">
          <div class="content">
            <products-list :products="promoPage.products"
                           :companyName="promoPage.title"
                           :showAll="navSelected === 'products'"
                           :class="{
              'products-list-short' : navSelected !== 'products',
              'products-list-full' : navSelected === 'products'
           }"
            ></products-list>
          </div>
        </div>
      </div>

      <!--    ----------------->
      <div class="field-block field-block-team" v-if="navSelected === 'info' || navSelected === 'team'">
        <div class="field-header">
          <div class="title">{{$t('title.team')}}</div>
          <div class="action" v-if="navSelected !== 'team'">
            <span class="btn cur-p" @click="showAllTeam()">{{
              $t('button.seeAll')}}</span>
          </div>
        </div>
        <div class="field-content enumerable show-all">
          <div class="content-item cur-p team-member"
               v-for="(item, key) in teamMembers"
               :key="key"
               @click="showProfile(item.contact.id)"
               v-show="key < 4 || navSelected === 'team'"
          >
            <div class="avatar round-radius m-auto">
              <img
                  :src="setAvatar(item.contact.photo_url)"
                  alt="img"
              />
            </div>
            <div class="team-member-info" v-if="item.displayTexts">
              <div class="team-member-name-part" v-if="item.displayTexts.name || item.displayTexts.surname">
                <div class="team-member-name" v-if="item.displayTexts.name">{{ item.displayTexts.name }}</div>
                <div class="team-member-surname" v-if="item.displayTexts.surname">{{ item.displayTexts.surname }}</div>
              </div>
              <div class="team-member-job-part" v-if="item.displayTexts.position || item.displayTexts.company">
                <div class="team-member-position" v-if="item.displayTexts.position">{{ item.displayTexts.position }}</div>
                <div class="team-member-company" v-if="item.displayTexts.company">{{ item.displayTexts.company }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Documents -->
      <document-list
          class="field-block field-block-documents"
          :class="{
            'show-action': navSelected === 'info',
            'show-all': navSelected === 'documents'
          }"
          :docs="promoPage.files"
          :showAll="navSelected === 'documents'"
          @seeAllDocuments="navSelect('documents')"
          v-if="promoPage.files && promoPage.files.length && (navSelected === 'info' || navSelected === 'documents')"
      ></document-list>


      <!-- Notes -->
      <notes-list
          class="field-block field-block-notes"
          v-if="noteList && noteList.length && (navSelected === 'info' || navSelected === 'notes')"
          :notes="noteList"
          :showAll="navSelected === 'notes'"
          :showActionButton="navSelected !== 'notes'"
          @seeAllNotes="navSelect('notes')"
      ></notes-list>


      <div class="field-block" v-if=" navSelected === 'messages'">
        <div class="field-content messages">
          <div class="content-text messages-text">
            <mu-text-field v-model="messageText"
                           class="text-input"
                           full-width
                           multi-line
                           :rows="5"
                           :placeholder="$t('title.type_your_message')"></mu-text-field>
            <mu-button class="send-btn"
                       small
                       round
                       @click="sendMessage()">{{$t('button.send')}}
            </mu-button>
          </div>
        </div>
      </div>


      <div class="field-block" v-if=" navSelected === 'messages'">
        <div class="field-content messages">
          <div class="content-text messages-text">
            <mu-text-field v-model="messageText"
                           class="text-input"
                           full-width
                           multi-line
                           :rows="5"
                           :placeholder="$t('title.type_your_message')"></mu-text-field>
            <mu-button class="send-btn"
                       small
                       round
                       @click="sendMessage()">{{$t('button.send')}}
            </mu-button>
          </div>
        </div>
      </div>
    </div>
    <router-view
      :setNavSelected="profileNavSelected"
      name="promoHallCompanyProfile" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
// import axios from 'axios';
import companyHeadNav from './components/promoPage/companies/company-head-nav'
import truncate from 'vue-truncate-collapsed';
import productsList from './components/products/productList';
import { FileNameHelper } from '@/helpers/filename.helper';
import DocumentList from './components/promoPage/documents/documentList';
import NotesList from './components/promoPage/notes/notesList';
import iconZoomOut from '@/_modules/icons/components/icon-zoom-out.vue';
// import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
// import MeetingRoomsHelper from '@/_modules/meeting-rooms/helpers/meeting-rooms.helper';
import Statistics from '@/services/statistics';
import CompanyMediaBlock from '@/_modules/promo/components/company-media-block/company-media-block.vue';
import { ContactsFilterType } from '@/_modules/contacts/types/contacts-filter.type';
import ChatHelper from "@/_modules/chat/helpers/chat.helper";

// const BROADCASTS_CHECK_INTERVAL = 5000;

const beforeRouteEnter = { to: null, from: null };

export default {
  name: 'promo-company',
  components: {
    NotesList,
    DocumentList,
    companyHeadNav,
    truncate,
    productsList,
    iconZoomOut,
    CompanyMediaBlock,
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
      messageListPromoPageAll: '_messageStore/messages',
      contact: 'promoPageStore/contact',
      promoPage: 'promoStore/promoPage',
    }),
    ...mapState({
      promoOwnerId(state) {
        this.updateNotesList();

        this.owner_id = state.promoStore.promoOwnerId;
        this.setMessages();

        return state.promoStore.promoOwnerId;
      },
      promoOwnerContactId(state) {
        this.profileNavSelected = 'messages';
        return state.promoStore.promoOwnerContactId;
      },
      attachedContacts: state => state.promoStore.promoPage.attached_contacts,
      noteList(state) {
        return state.noteStore.noteListPromoPage.List
      },
      promoPageListLoading: state => state.promoStore.promoPageListLoading
    }),
    isEventOrganizer() {
      if (this.event && this.contact && this.contact.user) {
        return this.event.creator_user_id === this.contact.user.id;
      }
      return false;
    },
    isAllowedToViewPromopageViewers() {
      if (this.isEventOrganizer) {
        return true;
      }
      if (!this.promoPage || !this.promoPage.attached_contacts || !this.promoPage.attached_contacts.length || !this.contact) {
        return false;
      }
      return !!this.promoPage.attached_contacts.find((attachedContact) => attachedContact.contact.id === this.contact.id);
    },
    teamMembers() {
      if (!this.promoPage || !this.promoPage.attached_contacts) {
        return [];
      }
      return this.promoPage.attached_contacts.map(member => {
        return {
          ...member,
          displayTexts: {
            name: member.contact && member.contact.name ? this.truncateMemberInfoString(member.contact.name, 10) : '',
            surname: member.contact && member.contact.surname ? this.truncateMemberInfoString(member.contact.surname, 10) : '',
            position: member.contact && member.contact.company_position ? this.truncateMemberInfoString(member.contact.company_position, 10) : '',
            company: member.contact && member.contact.company_name ? this.truncateMemberInfoString(member.contact.company_name, 10) : '',
          }
        }
      });
    },
    eventId() {
      return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
    },
    external_id() {
      return this.$route.params.external_id; // external_id is string, so no truey checks
    },
  },

  beforeRouteEnter(to, from, next) {
    beforeRouteEnter.to = to;
    beforeRouteEnter.from = from;
    next();
  },

  created() {
    this.initPromoPage();
    // this._startBroadcastsCheck();
  },

  mounted() {
    this.updateNotesList();
  },

  // beforeDestroy() {
  //   this._stopBroadcastsCheck();
  // },

  watch: {
    external_id: {
      immediate: true,
      handler() {
        Statistics.promopageView({
          eventId: this.eventId,
          externalId: this.external_id,
        }, beforeRouteEnter);

        this.updateNotesList();
        this.initPromoPage();
      },
    },

    'promoPage.is_favorite': {
      handler() {
        this.isFavoriteCompany = this.promoPage.is_favorite
      },
    },

    promoOwnerContactId: {
      immediate: true,
      handler() {
        if (!this.contact_id) {
          this.profileNavSelected = 'messages';
          this.showProfile(this.promoOwnerContactId);
        }
      }
    },
    isShowTeam: {
      handler() {
        this.showTeam = this.isShowTeam;
      }
    },
    isShowDocuments: {
      handler() {
        this.showDocuments = this.isShowDocuments;
      }
    },
    isShowNotes: {
      handler() {
        this.showNotes = this.isShowNotes;
      }
    },
  },
  props: {
    isShowTeam: {
      type: Boolean,
    },
    isShowDocuments: {
      type: Boolean,
    },
    isShowNotes: {
      type: Boolean,
    },
  },
  data() {
    return {
      list: null,
      noteFav: true,
      showMore: false,
      more: this.$t('title.documents'),
      showTeam: false,
      showProducts: false,
      showDocuments: false,
      showNotes: false,
      contact_id: null,
      company_index: null,
      isFavoriteCompany: false,
      showMoreId: null,
      text: '',
      owner_id: null,
      isOwner: false,
      messageText: '',
      navSelected: 'info',
      profileNavSelected: '',
      fullscreenMode: false,
      isLoading: false,
      // isBroadcastsCheckEnabled: true,
      // broadcasts: [],
    };
  },
  methods: {

    async initPromoPage() {
      await this.$store.dispatch('promoStore/getPromoPage', {
        external_id: this.external_id,
        event_id: this.eventId
      });

      this.isFavoriteCompany = this.promoPage.is_favorite;
    },

    /* Duplicated from edit-EventPromoHallCompany.vue TODO: code reuse (i.e. via helper) */
    truncateMemberInfoString(input, toLength) {
      if (input && input.length > toLength) {
        const truncatePostfix = '…';
        input = input.substr(0, toLength) + truncatePostfix
      }
      return input;
    },

    /* Prepares params
     * and dispatches callNoteListAllPromoPage
     */
    updateNotesList() {
      let payload = {};
      if (this.eventId) {
        payload.event_id = this.eventId;

        // Does not make sense without event_id
        if (this.$route.params.external_id) {
          payload.external_id = this.$route.params.external_id;
        }
      }
      if (payload.event_id) {
        this.$store.dispatch('noteStore/callNoteListAllPromoPage', payload);
      }
    },
    setAvatar(url) {
      let noPhoto;
      if (url) {
        return url
      } else {
        noPhoto = require('../assets/images/no-avatar-60x60.svg');
      }
      return noPhoto;
    },
    setCompanyAvatar(url) {
      let noPhoto;
      if (url) {
        return url
      } else {
        noPhoto = require('../assets/images/no-avatar-company-60x60.svg');
      }
      return noPhoto;
    },
    docName(val) {
      return FileNameHelper.getFileName(val);
    },
    docType(val) {
      return FileNameHelper.getFileExtension(val);
    },
    showAllTeam() {
      this.showTeam = !this.showTeam;
      this.navSelect('team')
    },
    showAllProducts() {
      this.showProducts = !this.showProducts;
      this.navSelect('products')
    },
    showAllDocuments() {
      this.showDocuments = !this.showDocuments;
      this.navSelect('documents')
    },
    showProfile(contact_id) {

      if (!contact_id) {return;}

      let payload = {
        event_id: this.eventId || this.$route.params.eventId,
        contact_id: contact_id
      };

      this.isLoading = true;
      this.$store.dispatch('promoStore/getProfileByContactId', payload).then(() => {
        this.$router.push({name: 'promo-profile', params: {contact_id: contact_id}}).catch(() => {
        });
        this.isLoading = false;
      })
    },
    async setMessages() {
      if (this.eventId && this.owner_id) {

        await this.$store.dispatch('_messageStore/callMessageList',
          {
            eventId: this.eventId,
            userId: this.owner_id,
          })

        if (this.messageListAll && this.messageListPromoPageAll.List) {
          this.messageList = this.messageListPromoPageAll.List
        }
      }
    },
    async sendMessage() {
      if (this.messageText && this.messageText.trim()) {
       await this.$store.dispatch('_messageStore/sendMessage',
          {
            eventId: this.eventId,
            userId: this.owner_id,
            text: this.messageText.trim()
          })

       await this.setMessages();
        this.messageText = '';
      }
    },
    close() {
      this.$router.push({name: 'promo-page-events-companies'}).catch(() => {
      });
    },
    toggleFavFromCompanyHeadNav(bool) {
      this.isFavoriteCompany = bool;
      let payload = {
        event_id: this.eventId,
        external_id: this.external_id
      };

      if (!bool) {
        this.$store.dispatch('promoPageStore/removeFavorite', payload);
      } else {
        this.$store.dispatch('promoPageStore/addFavorite', payload);
      }
    },
    goToOwnerMessages() {
      this.profileNavSelected = 'messages';
      this.showProfile(this.promoOwnerContactId);
    },
    navSelect(value) {
      this.navSelected = value || 'team';
      window.scrollTo(null, 0);
    },
    truncatedText(text, length) {
      if (text) {
        if (!length || length < 0) {
          length = text.length;
        }
        if (text.length > length) {
          return `${text.substring(0, length)}...`
        }
        return text;
      }
    },
    enableFullscreenMode(bool) {
      this.fullscreenMode = bool;
      this.$store.dispatch('promoPageStore/setIsMediaBlockOverlayVisible', bool);
    },

    proceedToPromopageViewerContacts() {
      try {
        this.$router.push({
          name: 'promo-contacts',
          query: {
            type: ContactsFilterType.BOOTH_VIEWERS,
            boothExternalId: this.$route.params.external_id
          }
        });
      } catch (e) {
        /* ignore */
      }
    },
    generateLinks(text) {
      return ChatHelper.createLinks(text);
    },

    // _stopBroadcastsCheck() {
    //   this.isBroadcastsCheckEnabled = false;
    // },
    // async _sleep(milliseconds) {
    //   await new Promise(resolve => {
    //     setTimeout(() => {
    //       resolve();
    //     }, milliseconds);
    //   });
    // },
    // async _startBroadcastsCheck() {
    //   while (this.isBroadcastsCheckEnabled) {
    //     await this._broadcastsCheck();
    //   }
    // },
    // async _broadcastsCheck() {
    //   if (!this.attachedContacts || !this.attachedContacts.length) {
    //     this.broadcasts = [];
    //     await this._sleep(BROADCASTS_CHECK_INTERVAL);
    //     return;
    //   }
    //
    //   const attachedContacts = this.attachedContacts;
    //   const eventId = Number(this.$route.params.eventId);
    //
    //   for (const attachedContact of attachedContacts) {
    //     const { contact } = attachedContact;
    //     const broadcastUrl = MeetingRoomsHelper.getBroadcastUrl({
    //       type: MeetingRoomType.BROADCAST,
    //       eventId: parseInt(this.$route.params.eventId, 10),
    //       contactId: contact.id
    //     });
    //
    //     let checkResult;
    //     try {
    //       checkResult = await axios({method: 'GET', url: broadcastUrl, timeout: 2000});
    //     } catch { /* do nothing */ }
    //
    //     if (!this.isBroadcastsCheckEnabled) {
    //       this.broadcasts = [];
    //       return;
    //     }
    //     if (attachedContacts !== this.attachedContacts || eventId !== Number(this.$route.params.eventId)) {
    //       /* something has changed */
    //       this.broadcasts = [];
    //       await this._sleep(BROADCASTS_CHECK_INTERVAL);
    //       return;
    //     }
    //
    //     const broadcastUrlIndex = this.broadcasts.indexOf(broadcastUrl);
    //     if (checkResult) {
    //       if (broadcastUrlIndex < 0) {
    //         this.broadcasts.push(broadcastUrl);
    //       }
    //     } else {
    //       if (broadcastUrlIndex > -1) {
    //         this.broadcasts.splice(broadcastUrlIndex, 1);
    //       }
    //     }
    //     await this._sleep(BROADCASTS_CHECK_INTERVAL);
    //   }
    // },
  }
};
</script>

<style scoped lang="scss">

  .overlay {
    position: fixed;
    z-index: 99;
    top: 60px;
    left: 60px;
    width: calc(100% - 60px - 60px); // Because of the new menu and BAD stacking context planning
    height: calc(100% - 60px);
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.6s;
    padding: 200px;
    pointer-events: none;

    .company-mediablock {
      width: 100%;
    }

  }

  .overlay img {
    max-width: 90%;
    max-height: 90%;
    width: auto;
    height: auto;
    transform: scale(0.95);
    transition: transform 0.3s;
  }

  .overlay-visible {
    visibility: visible;
    outline: none;
    cursor: default;
    opacity: 1;
    pointer-events: all;
  }

  .overlay-visible img {
    transform: scale(1);
  }

  .close-icon {
    position: absolute;
    right: 20px;
    top: 100px;
    cursor: pointer;
  }

  .flex-row {
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
    align-items: flex-start;
  }

  .company-wrapper {
    width: calc(100% - 410px); // AW-734 100% - ширина колонки с профилем - щель между колонками
    min-width: 448px; // AW-734, AW-1334
    height: auto;
    box-shadow: 5px 5px 15px #3754aa1a;
    border-radius: 10px;
    background-color: $ew-white;
    z-index: 1;
    margin-right: 8px;
    margin-left: 8px;
  }

  .truncated-text {
    height: 95px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .company {
    padding: 0 25px;
    color: $ew-black;

    .head-block {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .avatar {
        width: 90px;
        height: 90px;
        min-width: 90px;
        border-radius: 16px;
        opacity: 1;
        position: relative;
        overflow: hidden;
        border: 1px solid #eaeaea;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: 50% 50%;
        }
      }

      .title {
        text-align: left;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        margin-left: 20px;
        word-break: break-word;
        width: 100%;
        max-width: 100%;
        text-overflow: ellipsis;

        .max-width {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .location,
      .website {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
      }

      .website {
        word-break: break-word;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        a {
          color: $ew-blue;
        }
      }
    }

    .nav {
      display: flex;
      justify-content: space-between;
      padding: 32px 0;

      .tab {
        text-align: left;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        text-decoration: none;
        opacity: 0.8;
      }

      .highlight {
        border-bottom: 1px solid $ew-blue;
        color: $ew-blue;
      }
    }

    .content {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .description {
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        word-wrap: break-word;
      }
    }
  }

  html[lang=ru] .nav a {
    font-size: 12px;
  }

  .field-block {
    padding: 10px 25px;
    position: relative;
    overflow: hidden;

    .field-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 8px;

      .title {
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0;
        color: $ew-black;
        opacity: 0.8;
        position: relative;

        &::before {
          display: block;
          position: absolute;
          left: -25px;
          bottom: 2px;
          content: "";
          border-bottom: 1px solid #eaeaea;
          width: 25px;
        }
      }

      .btn {
        text-align: left;
        font-size: 12px;
        line-height: 14px;
        color: #00b6f8;
        display: block;

        ::first-letter {
          text-transform: uppercase;
        }
      }
    }

    .enumerable { // TODO: better name of the class
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: row wrap;
      overflow: hidden;
      height: 140px;
    }

    .flex-column-center {
      display: flex;
      justify-content: center;
      flex-flow: column wrap;
      align-items: center;
    }

    .messages {

      .messages-text {
        display: flex;
        flex-flow: column wrap;
      }

      .text-input {
        width: 100%;
        text-align: left;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
      }

      .send-btn {
        background-color: #00b6fb;
        color: $ew-white;
        width: 120px;
        height: 22px;
        margin-left: auto;
      }

      .subtitle {
        text-align: center;
      }

      .date_text {
        text-align: center;
        font-size: 8px;
        letter-spacing: 0;
        color: #000;
        opacity: 0.35;
        margin-bottom: 20px;
      }

      .text_title {
        font-size: 8px;
        margin-bottom: 6px;
      }

      .text {
        font-size: 12px;
      }
    }

    .field-content {
      padding: 16px 0;

      .square-radius {
        border-radius: 5px;
      }

      .round-radius {
        border-radius: 50%;
      }

      .content-item {
        margin-bottom: 26px;
        text-align: left;
        width: 25%;
        word-wrap: break-word;
        min-height: 120px;

        &:hover {
          background-color: darken($ew-gray-lightest, 1%);
        }

      }

      .description {
        word-wrap: break-word;
      }

      .avatar {
        width: 60px;
        height: 60px;
        min-width: 60px;
        position: relative;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .title {
        margin-top: 6px;
        max-width: 70px;
        margin-left: auto;
        margin-right: auto;
      }

      .title,
      .subtitle {
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        color: $ew-black;
        opacity: 0.8;
      }
    }
  }

  .show-all {
    height: auto !important;
  }

  .m-auto {
    margin: auto;
  }

  .field-block-team {
    .field-content {
      .avatar {
        width: 60px;
        height: 60px;
        min-width: 60px;
        margin-bottom: 8px;
      }
    }

    .team-member { // TODO: reuse styles. Duplicates styles in edit-EventPromoHallCompany.vue
      width: 25%;
      text-align: center;
      word-wrap: break-word;
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 14px;

      &-info {
        text-align: center;

        .team-member-name-part {
          margin-bottom: 6px;
        }

        .team-member-job-part {
          font-weight: 300;
          color: $ew-gray;
        }
      }
    }
  }

  .company-wrapper-skeleton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }

  .promopage-viewers-wrap {

    position: absolute;
    bottom: 100%;
    right: 0;
    text-align: right;
    margin-bottom: 4px;
    justify-content: flex-end;
    display: flex;
    width: 100%;

    .promopage-viewers {
      background-color: transparent;
      border-style: none;
      padding: 0;
      margin: 0;
      -webkit-appearance: none;
      outline: none;
      font-size: 12px;
      line-height: 14px;
      color: $ew-blue;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: $ew-red;
      }
    }
  }

</style>

<style lang="scss">

  .read-more {
    color: $ew-blue !important;
    text-decoration: none;
    cursor: pointer;
  }

  .more-btn {
    text-align: left;
    font-size: 12px;
    color: $ew-blue !important;
  }
</style>
